import { DIRECT_TO_APP_UX } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useDirectToAppUx = () => {
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: DIRECT_TO_APP_UX.EXPERIMENT_KEY,
    fallbackVariation: DIRECT_TO_APP_UX.VARIATIONS.CONTROL,
  })
  const TREATMENT_VARIATIONS = [
    DIRECT_TO_APP_UX.VARIATIONS.VARIANT_1,
    DIRECT_TO_APP_UX.VARIATIONS.VARIANT_2,
    DIRECT_TO_APP_UX.VARIATIONS.VARIANT_3,
  ]

  return {
    isLoading,
    isTreatment: isEnabled && TREATMENT_VARIATIONS.includes(variation),
    isVariant1:
      isEnabled && variation === DIRECT_TO_APP_UX.VARIATIONS.VARIANT_1,
    isVariant2:
      isEnabled && variation === DIRECT_TO_APP_UX.VARIATIONS.VARIANT_2,
    isVariant3:
      isEnabled && variation === DIRECT_TO_APP_UX.VARIATIONS.VARIANT_3,
  }
}
